.MuiBottomNavigationAction-root.big-menu-button {
    margin: auto;
    margin-bottom: 27px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px;
    height: 60px;
    width: 60px;
    margin: auto;
    background-color: #6A3EA1;
    ;
    padding: 0px;
    flex: 0 0 !important;
    min-width: 60px;
    max-width: 60px;
}

.big-menu-button>.MuiBottomNavigationAction-label {
    color: white;
}

.MuiBottomNavigation-root.big-menu-button-container {
    height: 80px;
    width: 80px;
    display: flex;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 36px;
    background-color: white;
    box-shadow: 0px 0px 10px -2px;
    padding: 0px;
}