.meeting-timing {
    height: 22px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;
}

.meeting-menu-title {
    height: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    flex: none;
    order: 1;
    flex-grow: 0;
}